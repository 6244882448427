import { Injectable } from "@angular/core";
import { ApiService } from "../services/api.service";
import { Observable, Observer, Subject } from "rxjs";
import { debounceTime } from 'rxjs/operators';
import { ContentInterface, ContentListInterface} from '../interfaces/content.interface';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HelpersService } from '../services/helpers.service';
import { DraftModel } from "./draft.model";
import { AuthService } from '../services/auth.service';

import * as Alert from '../services/alert.service';
import { ToastService } from '../services/toast.service';
import * as _ from "lodash";
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";


@Injectable()
export class ContentModel {

    public contentList      : ContentListInterface[];    
    public formdata$        : Observable<ContentInterface>;
    private _formdata       : ContentInterface;
    public detailObserver   : Observer<ContentInterface>;
    public contentForm      : FormGroup;
    public draftSaving      : Boolean = false;
    public draftSubscription: any;
    public autoDraftAfterMs : number = 10000;
    public previousFormData : any;
    public msId: number;

    constructor(
        private apiService: ApiService,
        private draftModel  : DraftModel,
        private fb: FormBuilder,
        private helper: HelpersService,
        private toastrService: ToastService,
        public authService: AuthService,
        protected http    : HttpClient
    ) 
    {
        this.resetForm();

    }

	/**
	 * used to paginate
	 * @param params id, name
	 * @param cb
	 * @author Mukesh Soni <mukesh.soni@timesinternet.in>
	 */
	paginate(params, cb, errCb) {
		let paginateUrl = "/api/content/list";
		let httpOptions = Object.assign({}, params);
		this.apiService.get(paginateUrl,(resp) => {
            if (_.get(resp, "code") == 200) {
                cb(resp.data);            
            }
        },errCb,true,httpOptions);
	}

	/**
	 * used to save details back to server for add/edit operation
	 * @param any postdata
	 * @param function cb  success callback
	 * @param function errCb  error callback
	 * @author Mukesh Soni <mukesh.soni@timesinternet.in>
	 */
	save(postdata, cb, errCb) {
		let id = _.get(postdata, "content_id");
		let saveUrl = id ? "/api/content/update/" + id : "/api/content/add";
		this.apiService.post(saveUrl,postdata,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        }, 
        errCb, true);
	}

	/**
	 * used to get details
	 * @param int id
	 * @param function cb  callback
     * @param function errCb error callback
	 * @author Mukesh Soni <mukesh.soni@timesinternet.in>
	 */

	getDetail(id, cb, errCb, params?) {
		let detailUrl = "/api/content/detail/" + id;
		this.apiService.get(detailUrl,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        },
        errCb, true, params);
	}

    getCredits(cb , errCb) {
		let creditsUrl = "/api/story-credit/dropdown";
		this.apiService.get(creditsUrl,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
		},
        errCb, true);
    }
    
    getAnalytics(postdata, cb, errCb) {
		let fetchUrl = "/api/analytics/fetchurl";
		this.apiService.post(fetchUrl,postdata,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        },
        errCb, true);
    }

	delete(id, cb, errCb) {
		let deleteUrl = "/api/content/delete/" + id;
		let postdata = { id: id };
		this.apiService.post(deleteUrl,postdata,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        },
        errCb, true);
    }

    /**
     * @uses: To read google doc
     * @Author: Ashish Anand <ashish.anand@timesinternet.in> 
     */
     getGoogleDocData(id, cb, errCb,params?) {
		let detailUrl = "/api/google-doc/read/" + id;
		this.apiService.get(detailUrl,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        },
        errCb, true, params);
	}

    /**
     * 
     * @uses: To Purge akamai cache of frontend and API
     * @Author: Naveen Gupta <naveen.gupta@timesinternet.in> 
     *        
     */
    purgeAkamaiCache(params, cb, errCb){
        let id = params.params['id'];
        let status = params.params['status']
        let localeId = this.authService.locale.id;
        let purgeUrl = (status == 2)?"/api/akamai/purge-content/delete/" + id:"/api/akamai/purge-content/invalidate/" + id;
        purgeUrl = purgeUrl+"?locale_id="+localeId;
        let postdata = { };
		this.apiService.get(purgeUrl,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        },
        errCb, true);
     }

    /**
     * all translation before showing form will be done here
     * @author Mukesh Soni <mukesh.soni@timesinternet.in>
     * @param apiResp 
     */
    prepareForm(apiResp){
        let formdata = Object.assign({},
            _.get(apiResp,'content'), 
            {seo_meta: _.get(apiResp,'seo_meta',{})}, 
            {category_tags: _.get(apiResp,'category_tags',[])},
            {categories: _.get(apiResp,'categories',[])},
            {shop_categories: _.get(apiResp,'shop_categories',[])},
            {codes: _.get(apiResp,'codes',{})},
            {reviews: _.get(apiResp,'reviews',{})},
            {credits: _.get(apiResp,'credits',{})},
            {flags: _.get(apiResp,'flags',[])},
            {media: _.get(apiResp, 'media',{})},
            {idiva_extras: _.get(apiResp, 'idiva_extras',{})},
            {indiatimes_extras: _.get(apiResp, 'indiatimes_extras',{})},
        );
        let publish_date = moment(formdata.publish_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY hh:mm:ss a');
        formdata.publish_date = publish_date;

        if (formdata.description){
            let d = $("<div>"+formdata.description+"</div>")
            d.find( "p.caption-image-wrapper" ).attr("contenteditable", "false")
            d.find( "span.editor-highlight" ).attr("contenteditable", "false")
            d.find( "span.editor-topic" ).attr("contenteditable", "false")
            formdata.description = d.html()
        }
        return formdata;
    }

     /**
     * all data translation before hitting api will be done here.
     * @author Mukesh Soni <mukesh.soni@timesinternet.in>
     */
    prepareApi(){
        let postdata = Object.assign({}, this.contentForm.value);
        let authors = [];
        if(postdata.authors.length > 0){
            postdata.authors.forEach( (indv)=>{
                authors.push({author_id:indv.id});
            });
            postdata.authors = authors;
        }

        let categories = [];
        if(postdata.categories.length > 0){
            postdata.categories.forEach( (indv)=>{
                categories.push({category_id:indv.id});
            });
            postdata.categories = categories;
        }

        let shop_categories = [];
        if(postdata.shop_categories && postdata.shop_categories.length > 0){
            postdata.shop_categories.forEach( (indv)=>{
                shop_categories.push({category_id:indv.id});
            });
        }
        postdata.shop_categories = shop_categories;

        if(postdata.publish_date){
            let publishDate = moment(postdata.publish_date,"DD-MM-YYYY hh:mm:ss a").format('YYYY-MM-DD HH:mm:ss');
            postdata.publish_date = publishDate;
        }

        if(postdata.description){
            let descData = $('<div>'+postdata.description+'</div>');
            descData.find( "p.caption-image-wrapper" ).removeAttr('contenteditable');
            descData.find( "span.editor-highlight" ).removeAttr('contenteditable');
            descData.find( "span.editor-topic" ).removeAttr('contenteditable');
            postdata.description = descData.html();
        }
        
        
        postdata = this.helper.cleanPost(postdata);
        return postdata;
    }

    /**
     * load draft and update form
     * @param draftId 
     */
    loadDraft(draftId, cb, errCb){
        this.draftModel.getDetail(draftId, cb, errCb);
    }
    
    /**
     * broadcast new formdata value to subscribers
     * @param any formdata 
     */
    updateFormdata(formdata){
        this._formdata = formdata;
        this.contentForm.patchValue(formdata);
        this.detailObserver.next(formdata);
    }

    enableAutoDraft(){
        console.log("Auto Draft Save Enabled");
        var draftChanges = this.contentForm.valueChanges.pipe( 
            debounceTime(this.autoDraftAfterMs)
        );
        
        this.draftSubscription = draftChanges.subscribe( newVal => {            
            if(!this.previousFormData){
                this.previousFormData = JSON.stringify(this.contentForm.value);
            }
            // Content Form gets updates from widgets but sometime data is not changed. 
            // Draft save will call only if there is any change in data
            let formUpdate = JSON.stringify(newVal);
            if(this.previousFormData != formUpdate){
                this.saveDraft(()=>{
                    this.previousFormData = formUpdate;
                }, ()=>{
                   //draft save faild errCb 
                });
            }
        });

        
    }

    disableAutoDraft(){
        this.previousFormData = "";
        if(this.draftSubscription){
            this.draftSubscription.unsubscribe();
        }
    }

    prepareDraftApi(formValue){
        let postdata = Object.assign({ preview_url: this._formdata.preview_url}, formValue);
        if(formValue.publish_date){
            let publishDate = moment(formValue.publish_date,"DD-MM-YYYY hh:mm:ss a").format('YYYY-MM-DD HH:mm:ss');
            postdata.publish_date = publishDate
        }
        return JSON.stringify(postdata);
    }

    prepareDraftForm(data){
        let formdata = Object.assign({}, JSON.parse(data.formdata), {draft_id:data.id});
        if(data.mapping_id){
            this.msId=data.mapping_id;
        }
        if(formdata.publish_date){
            let publish_date = moment(formdata.publish_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY hh:mm:ss a');
            formdata.publish_date = publish_date;
        }
        return formdata;
    }

    saveDraft(cb, errCb){
        console.log("draft save called");
        let formValue = this.contentForm.value;
        if(!this.draftSaving && _.get(formValue,'headline1')){
            let stringForm = this.prepareDraftApi(formValue);;
            this.draftSaving = true;
            let postdata = {};
            postdata['title'] = _.get(formValue,'headline1','');
            postdata['content_type_id'] = _.get(formValue, 'content_type_id');
            postdata['locale_id'] = _.get(formValue,'locale_id', 0);
            postdata['content_id'] = _.get(formValue,'content_id',0);
            postdata['formdata'] = stringForm;
            postdata['id'] = _.get(formValue,'draft_id');
            
            postdata = this.helper.cleanPost(postdata);

            this.draftModel.save(postdata, (respData)=>{
                this.toastrService.toast({type: "success", text:"Auto Draft Saved!",title:"Success"});
                this.draftSaving = false;
                if(_.get(respData,'id') && !postdata['id']){
                    this.contentForm.patchValue({draft_id:respData['id']});  
                    cb(respData);
                }
            }, (err)=>{
                this.draftSaving = false;
                console.error("Error on draft save: ", err);
                errCb(err);
            });
        }
    }

    /**
     * discard draft
     * @param draftId 
     */
    discardDraft(draftId){
        this.draftModel.delete(draftId, (apiResp)=>{
            console.log("after draft delete", apiResp);
        }, (err)=> {
            console.error("Error draft delete: ", err);
        });
    }

    /**
     * reset form detail and controls
     */
    resetForm(){

        let detailObserver = new Subject<any>();

        this.formdata$ = detailObserver.asObservable();
        this._formdata = {} as ContentInterface;
        
        this.detailObserver = detailObserver;

        this.contentForm = this.fb.group({});        
    }    

    /**
     * clear form data
     */
    clearForm(){
        this.contentForm.patchValue({} as ContentInterface);
    }

    publish(id, cb, errCb){
        let saveUrl = "/api/content/publish/" + id;
        let postdata = {id:id};
		this.apiService.post(saveUrl,postdata,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
		},errCb, true);
    }

    unpublish(id, cb, errCb){
        let saveUrl = "/api/content/unpublish/" + id;
        let postdata = {id:id};
		this.apiService.post(saveUrl,postdata,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
		},errCb, true);
    }

    getLogs(postdata, cb, errCb) {
		let fetchUrl = "/api/logs/list";
		this.apiService.post(fetchUrl,postdata,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
		},cb, errCb, true);
    }

    duplicate(id,params, cb, errCb){
        let saveUrl = "/api/content/duplicate/" + id;
        let httpOptions = Object.assign({}, params);
		this.apiService.post(saveUrl,{},resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
		},errCb, true, httpOptions);
    }

    translateContent(postdata, cb, errCb){
        let localeId = this.authService.locale.id;
        let url="/api/translate?locale_id="+localeId;
        this.apiService.post(url,postdata,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
		},cb, errCb, true);
    }

    getCategories(postdata, cb, errCb){
        let url="/api/classifier/categories/list";
        this.apiService.post(url,postdata,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
		},cb, errCb, true);
    }

    /**
	 * used to paginate
	 * @param params id, name
	 * @param cb
	 * @author Kushagra Tiwari <kushagra.tiwari@timesinternet.in>
	 */
	duplicatePaginate(params, cb, errCb) {
		let paginateUrl = "/api/content/duplicate/list";
		let httpOptions = Object.assign({}, params);
		this.apiService.get(paginateUrl,(resp) => {
            if (_.get(resp, "code") == 200) {
                cb(resp.data);            
            }
        },errCb,true,httpOptions);
	}

    /**
     * @uses: To translate content text using google api
     * @Author: Ashish Anand <ashish.anand@timesinternet.in> 
     */ 
     translateText(postdata, cb, errCb) {
        let apiUrl = "/api/google-doc/translate";
		this.apiService.post(apiUrl,postdata,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        },
        errCb, true);
    }

    /**
	 * get similar articles from Solr 
     * @author: vipul.garg<vipul.garg@timesinternet.in>
	 * @param content_id
     * @param text
	 */
    similarArticles(postdata, cb, errCb){
        let url = "/api/content/similar";
		this.apiService.post(url, postdata, resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
		},errCb, true);
    }

    /**
     * @uses: To verify duplicate content from global
     * @Author: Ashish Anand <ashish.anand@timesinternet.in> 
     */
    verifyDuplicate(params, cb, errCb) {
		let verifyUrl = "/api/content/verifyduplicate";
        let httpOptions = Object.assign({}, params);
		this.apiService.get(verifyUrl,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        },
        errCb, true, httpOptions);
	}
    
    /**
     * @uses: To verify credits of all assests in content
     * @Author: Ashish Anand <ashish.anand@timesinternet.in> 
     */
     verifyAssets(contentId, cb, errCb) {
		let verifyUrl = "/api/content/verify_assets/"+contentId;
        let httpOptions = {};
		this.apiService.get(verifyUrl,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        },
        errCb, true, httpOptions);
	}

    /**
     * @uses: create photogallery from ampstory
     * @Author: Mukesh Soni <mukesh.soni@ilnmedia.com> 
     */
    createPhotoGalleryByAmpStory(contentID, cb, errCb) {
		let reqUrl = "/api/content/duplicate-amp/"+contentID;
        let httpOptions = {};
        let postdata = {
            locale_id: this.authService.locale.id
        }
		this.apiService.post(reqUrl, postdata,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        },
        errCb, true, httpOptions);
	}

    /**
	 * used to get data after login
	 * @param params httpOptions
	 * @param cb
	 * @author Kushagra Tiwari <kushagra.tiwari@timesinternet.in>
	 */
	generateContent(postdata, cb, errCb) {
		let url = "/api/content/generate-content";
		this.apiService.post(url, postdata, resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
		},errCb, true);
    }

    /**
	 * used to get google trending searches data
	 * @param params httpOptions
	 * @param cb
	 * @author Toshendra Bohra <toshendra.bohra@ilnmedia.com>
	 */
	getGoogleTrendsData(params, cb, errCb) {
        let httpOptions = Object.assign({}, params);
        console.log("httpOptions", httpOptions);
		let url = "/api/trending/list";

        this.apiService.get(url,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        },
        errCb, true, httpOptions);
    }


    checkOriginality(id, cb, errCb){
        let saveUrl = "/api/content/verifyoriginal/" + id;
        let postdata = {id:id};
		this.apiService.post(saveUrl,postdata,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
		},errCb, true);
    }
    
    getTwitterTrendsData(params, cb, errCb) {
        let httpOptions = Object.assign({}, params);
		let url = "/api/trending/twitter/list";

        this.apiService.get(url,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        },
        errCb, true, httpOptions);
    }

    getTrendsCategories(cb, errCb, params?) {
        let httpOptions = Object.assign({}, params);
		let url = "/api/trending/list-categories";

        this.apiService.get(url,resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
        },
        errCb, true, params);

    }

    /**
	 * used to get data after login
	 * @param params httpOptions
	 * @param cb
	 * @author Kushagra Tiwari <kushagra.tiwari@timesinternet.in>
	 */
	generateSlides(postdata, cb, errCb) {
		let url = "/api/content/generate-slides";
		this.apiService.post(url, postdata, resp => {
            if (_.get(resp, "code") == 200 && _.get(resp, "data")) {
                cb(resp.data);
            }
		},errCb, true);
    }

}
