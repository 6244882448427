import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../../environments/environment";
import { crosswalk } from "../../assets/js/ssocrosswalk";
import { HelpersService } from "./helpers.service";
import { LocalstorageService } from "./localstorage.service"
import { ApiService } from "./api.service"
import * as _ from 'lodash';
import { SsoModel } from '../models/sso.model';
import * as Alert from "./alert.service";

@Injectable()
export class AuthService implements CanActivate {
    private crosswalk: any;
    
    private _userprofile : any;
    private _businessList: any;
    private _role        : any;
    private _locale      : any;
    private _permissionMap: any;

    private moduleList: Array<string> = [
        'content',
        'box',
        'microsite',
        'staticbox',
        'author',
        'category',
        'live-video',
        'sitewidgets',
        'ads-txt',
        'native',
        'topic',
        'seopage',
        'reporting',
        'frontendmenu',
        'ads-management',
        'staticpage',
        'horoscope',
        'tagsmanagement',
        'miscellaneous',
        'videocommerce',
        'comment',
        'adscreative',
        'fbads'
    ]
    
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
		private cookieService: CookieService,
        private helpersService: HelpersService,
        private localstorageService: LocalstorageService,
        private apiService: ApiService,
        private ssoModel: SsoModel,
	) {
		this.crosswalk = crosswalk();
	}

	get profile() {
        if(!this._userprofile){
            let userdata = this.cookieService.get("cm_user_profile");
            if(userdata && userdata != "undefined") {
                this._userprofile = JSON.parse(userdata);
            }
        }        
        return this._userprofile;
    }
    
    get userId(){
        return this.profile['user']['id'] ? this.profile['user']['id'] : 0;
    }

	get isLoggedIn() {
        return this.profile ? true : false;
    }

    get userType(){
        return _.get(this.profile,'user.user_type','member');
    }

    get isUserAdmin(){
        return (this.userType == 'super_admin' || this.userType == 'admin') ? true : false;
    }

    get appSettings() {
        let allLocaleListLabel = environment.LOCALE;
        let locale = _.get(this,'locale') ? this.locale : {name:'english'};
        locale['locale_lng'] = allLocaleListLabel[locale['name'].toLowerCase()];
        return {
            user: this.profile['user'],
            permission: this.profile['permission'],
            locale,
            business: this.business
        }
    }
    
    get business() {
        if(!this._businessList){
            this._businessList = this.localstorageService.getLocalStorage('cm_businesslist');            
        }
        return this._businessList;
    }

    get role() {
        if(!this._role){
            this._role = this.localstorageService.getLocalStorage('cm_role');
        }
        return this._role;
    }

    get businessSlug(){
        return this.business['short_name'] ? this.business['short_name'] : '';
    }

    get locale() {
        if(!this._locale){
            this._locale = this.localstorageService.getLocalStorage('cm_locale');
        }
        return this._locale;
    }

    get permissionMap(){
        if(!this._permissionMap){
            this._permissionMap = this.localstorageService.getLocalStorage('cm_permission');
        }
        return this._permissionMap;
    }

    set business(data){
        this.localstorageService.setLocalStorage('cm_businesslist', JSON.stringify(data));
        this.apiService.setApiBase();
    }

    /**
	 * Make changes required to login a user
	 * writing cookie "cm_user_profile" having login response in json string
	 * @author Mukesh Soni <mukesh.soni@timesinternet.in>
	 * @param any data
	 * @returns boolean on success
	 */
	login(data,ticketId?) {
        let userdata = JSON.stringify(data);
        let expiredDate = new Date();
		let currentdomain = this.helpersService.extractDomain();
		expiredDate.setMinutes(
			expiredDate.getMinutes() + environment.LOGIN_EXPIRE_TIME
        );
        
        this.cookieService.set(
			"cm_user_profile",
			userdata,
			expiredDate,
			"/",
			currentdomain
		);
        if(ticketId){
            this.cookieService.set(
                "central_sso_token",
                ticketId,
                expiredDate,
                "/",
                currentdomain
            );
        }
		return true;
    }
    
    /**
	 * Make changes required to logout a user
	 * @author Rishabh trivedi <rishabh.trivedi@timesinternet.in>
	 * @returns boolean on success
	 */

    logout() {
        if(environment.IS_CROSSWALK){
            this.crosswalkLogout();
        }
        else{
            this.centralSsoLogout();
        }

	}

    crosswalkLogout(){
        //this call is required to verify domain at sso end
        this.crosswalk.getValidLoggedInUser((resp) =>{
            this.crosswalk.signOutUser((resp)=>{
                if(_.get(resp, 'code') == 200 || _.get(resp,'code') == 404){
                    var currentdomain = this.helpersService.extractDomain();
                    this.cookieService.delete("cm_user_profile", "/", currentdomain);
                    this.localstorageService.clearLocalStorage();
                    window.location.assign(environment.CENTRAL_URL+'/login');
                }else{
                    console.log(resp);
                    alert(_.get(resp,'message'));
                }
            })
        });
    }

    centralSsoLogout(){
        let options = {
            'Sso-Access-Token':this.cookieService.get('central_sso_token')
        }
        this.ssoModel.logout(options,(resp)=>{
            if(_.get(resp, 'code') == 200 || _.get(resp,'code') == 404){
                var currentdomain = this.helpersService.extractDomain();
                this.cookieService.delete("cm_user_profile", "/", currentdomain);
                this.cookieService.delete("central_sso_token", "/", currentdomain);
                this.localstorageService.clearLocalStorage();
                window.location.assign(environment.CENTRAL_URL+'/login');
            }else{
                console.log(resp);
                this.handleErr(_.get(resp,'message'));
            }
        },(error)=>{
            this.handleErr(error)
        });
    }

    /**
	 * Check user is activated after app bootstrap in case of Dashbaord Layout
	 * @author Rishabh trivedi <rishabh.trivedi@timesinternet.in>
	 * @param current Dashboard Routes with state
	 * @returns boolean on success
	 */

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // logged in so return true
		if (this.isLoggedIn) {
			return true;
		}
		// not logged in so redirect to login page with the return url
		window.location.assign(`${environment.CENTRAL_URL}/login`);
		return false;
    }
    
    /**
	 * Check user is activated after app bootstrap in case of Dashbaord Layout
	 * @author Rishabh trivedi <rishabh.trivedi@timesinternet.in>
	 * @param current Dashboard Routes with state
	 * @returns boolean on success
	 */
    
    localeAlreadyExist(){
        let cmLocale = this.localstorageService.getLocalStorage('cm_locale');
        const queryStringLocale: string = this.activatedRoute.snapshot.queryParamMap.get('locale');
        if(cmLocale){
            if(queryStringLocale){
                if(cmLocale['locale_label'] == queryStringLocale){
                    return true;
                }else{
                    return false;
                }
            }
        }
        return false;
    }

    /**
	 * Add Locale data to locale
	 * @author Rishabh trivedi <rishabh.trivedi@timesinternet.in>
	 * @param Locale Data
	 * @returns boolean on success
	 */
    setlocale(data) {
        this.localstorageService.setLocalStorage('cm_locale', JSON.stringify(data));
    }


    /**
	 * Add Role data to locale
	 * @author Rishabh trivedi <rishabh.trivedi@timesinternet.in>
	 * @param Locale Data
	 * @returns boolean on success
	 */
    setrole(data) {
        this.localstorageService.setLocalStorage('cm_role', JSON.stringify(data));
    }

    /**
	 * Add module permission data to local storage
	 * @author Mukesh Soni <mukesh.soni@timesinternet.in>
	 * @param any data
	 * @returns boolean on success
	 */
    setModulePermission(data) {
        this.localstorageService.setLocalStorage('cm_permission', JSON.stringify(data));
    }


    /**
	 * checking for permission
	 * @author Rishabh trivedi <rishabh.trivedi@timesinternet.in>
	 * @param Locale Data
	 * @returns boolean on success
	 */
    canAccess(module, action) {

        let profile = this.profile;

        // no user loggedin
        if(!this.profile){
            this.logout();
        }

        // allow everything for admin && super admin
        if(profile.user.user_type == 'admin' || profile.user.user_type == 'super_admin'){
            return true;
        }
        
        if(!this.helpersService.checkControlPanel()){   
            if (this.permissionMap[module] == '*' || (this.permissionMap[module] && this.permissionMap[module].indexOf(action) > -1)){
                return true
            }
            
        }
        return false;
    }
    

    assignedModules(){
        let modules = [];
        if(!this.helpersService.checkControlPanel()){
            let business_id = _.get(this,'business.id');
            let local_id = this.locale.id;
            let profile = this.profile;

            if(profile.user.user_type == 'admin' || profile.user.user_type == 'super_admin'){
                for(let i=0; i < this.moduleList.length; i++){
                    if(profile.user.user_type == 'admin' || profile.user.user_type == 'super_admin'){
                        modules.push(this.moduleList[i]);
                    }
                }
                modules.push('roles');
            }
            else{
                let persmission = this.permissionMap;
                for(let i=0; i < this.moduleList.length; i++){
                    if((persmission[this.moduleList[i]] && persmission[this.moduleList[i]].length > 0) || profile.user.user_type == 'admin' || profile.user.user_type == 'super_admin'){
                        modules.push(this.moduleList[i]);
                    }
                }
            }
        }
        
        return modules;
    }

    handleErr(err){
        let allMsg = this.helpersService.prepareApiError(err);
        let errMsg = allMsg.join('<br/>');
        Alert.typeError("Error!", errMsg);
    }
}
