// export const environment = {
// 	production: true,
// 	env:'prod',
//     GOOGLE_CLIENT_ID: '875538389300-qathdh5lk33kunv7ia7c2cbho0l2mh9r.apps.googleusercontent.com',
//     GP_REDIRECT: 'http://local.whatshot.in/login/googleresponse',
//     CENTRAL_URL: 'http://cq.idiva.com',
//     CENTRAL_API_BASE: 'http://cq-api.idiva.com',
//     LOGIN_EXPIRE_TIME: 10 //IN Days
// };

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
    env:'prod',
    appName:'Cosmos',
    debug:true,
    pageSize:50,
	CENTRAL_URL: 'https://navik.ilnconnect.com',
    CENTRAL_API_BASE: 'https://navik-api.ilnconnect.com',
    FBADS_API_BASE: 'https://ad-insights.idiva.com',
    SSO_CROSSDOMAIN_URL: 'https://jsso.indiatimes.com/sso/crossdomain/validateTicket',
    SSO_CROSSWALK_JS:'https://jssocdn.indiatimes.com/crosswalk/jsso_crosswalk_0.2.5.min.js',
    LOGIN_EXPIRE_TIME: 21600, //in minutes,
    PYTHON_SERVER_DOMAIN:"https://datascience.ilnconnect.com",
    SSO_BASE: 'https://cmssso.ilnconnect.com',

    GOOGLE:{
        CLIENT_ID:"139101999132-s1p58oa9kccta24p0mp5615pbc1krktc.apps.googleusercontent.com",
        RETURN_URL: "https://navik.ilnconnect.com/login/googleresponse"
    },
    
    SSO:{
        CHANNEL_NAME: 'cosmos',
        DOMAIN_NAME: 'navik.ilnconnect.com'
    },
    
    LOCALE:{
        hindi: 'हिंदी',
        english: 'English',
        marathi: 'मराठी',
        punjabi: 'ਪੰਜਾਬੀ',
        bengali:'বাংলা'
    },

    UPLOADER_TYPE:{
        photogallery: 'photogallery',
        category: 'category',
        author: 'author',
        content: 'content',
        property: 'property',
    },

    TRIVIA_OPTION_TYPES:[
        {'id':1, 'name':'Long List'},
        {'id':2, 'name': 'Thumb Box'},
        {'id':3, 'name': 'Thumb Text'}
    ],

    CAROUSAL_LIMIT:{
        spotlight:10,
        box_carousal:100
    },
    IS_CROSSWALK : 0,

    WEBSITE_DOMAIN:{
        IDV:{
           1: 'https://www.idiva.com/test/test/test-test/',
           2: 'https://www.idiva.com/hindi/test/test/test-test/',
           3: 'https://www.idiva.com/marathi/test/test/test-test/',
        },
        MXP:{
            1:'https://www.mensxp.com/test/test/',
            2:'https://www.mensxp.com/hindi/test/test/',
            3:'https://www.mensxp.com/marathi/test/test/',
        },    
        IT:'https://www.indiatimes.com/test/test/test-',
    }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
