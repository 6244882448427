export const GlobalConstants = {
    PLATFORMS : ['web', 'mweb', 'amp', 'ia'],
    CATEGORY_TYPE_MICROSITE : 'master_section',

    /* for now, we are assuming contype will be property specific only */
    CONTYPES:{
        IDV:[
            // {id:0, content_type:"All", slug:"all", icon: 'ft-align-justify', path: '/content/all', title: 'All', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            {id:1, content_type:'News', slug:'news', icon:"ft-file", path:'/content/news',title: 'News Article', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:51, content_type:'Video', slug:'videos', icon:"ft-film", path: '/content/videos', title: 'Videos', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:5, content_type:'Photogallery', slug:'photogallery', icon:"ft-image", path: '/content/photogallery', title: 'Photogallery', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            {id:2, content_type:'Review', slug:'reviews', icon:"ft-book", path:'/content/reviews',title: 'Reviews', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:53, content_type:'Quiz', slug:'quiz', icon:"ft-square", path: '/content/quiz', title: 'Quiz',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:52, content_type:'Trivia Quiz', slug:'trivia-quiz', icon:"ft-circle", path: '/content/trivia-quiz', title: 'Trivia Quiz', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:6, content_type:'Opinions', slug:'opinions', icon:"ft-droplet", path: '/content/opinions', title: 'Opinions', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            {id:3, content_type:'Interviews', slug:'interviews', icon:"ft-target", path: '/content/interviews', title: 'Interviews', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            /** quiz is not treated as contype in old idiva system, assuming it will be a new type for new system */
            {id:54, content_type:'Click To Reveal', slug:'click-to-reveal', icon:"ft-aperture", path: '/content/click-to-reveal', title: 'Click to Reveal', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:55, content_type:'Slide To Reveal', slug:'slide-to-reveal', icon:"ft-chevron-left", path: '/content/slide-to-reveal', title: 'Slide to Reveal',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:60, content_type:'Polls', slug:'polls', icon:"ft-bar-chart", path: '/content/polls', title: 'Polls',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            // {id:61, content_type:'Special Story', slug:'ampstory', icon:"ft-bar-chart", path: '/content/ampstory', title: 'Special Story',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []}
        ],
        MXP:[
            // {id:0, content_type:"All", slug:"all", icon: 'ft-align-justify', path: '/content/all', title: 'All', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },            
            {id:1, content_type:'News', slug:'news', icon:"ft-file", path:'/content/news',title: 'News Article', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:51, content_type:'Video', slug:'videos', icon:"ft-film", path: '/content/videos', title: 'Videos', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:5, content_type:'Photogallery', slug:'photogallery', icon:"ft-image", path: '/content/photogallery', title: 'Photogallery', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            {id:56, content_type:'Movie Reviews', slug:'movie-reviews', icon:"ft-award", path: '/content/movie-reviews', title: 'Movie Review',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:53, content_type:'Quiz', slug:'quiz', icon:"ft-square", path: '/content/quiz', title: 'Quiz',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},            
            {id:52, content_type:'Trivia Quiz', slug:'trivia-quiz', icon:"ft-circle", path: '/content/trivia-quiz', title: 'Trivia Quiz', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:3, content_type:'Interviews', slug:'interviews', icon:"ft-target", path: '/content/interviews', title: 'Interviews', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:54, content_type:'Click To Reveal', slug:'click-to-reveal', icon:"ft-aperture", path: '/content/click-to-reveal', title: 'Click to Reveal', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:55, content_type:'Slide To Reveal', slug:'slide-to-reveal', icon:"ft-chevron-left", path: '/content/slide-to-reveal', title: 'Slide to Reveal',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:57, content_type:'Tech Reviews', slug:'tech-reviews', icon:"ft-zap", path: '/content/tech-reviews', title: 'Tech Review',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:59, content_type:'Ecom Looks', slug:'ecom-looks', icon:"ft-layout", path: '/content/ecom-looks', title: 'Ecom Looks', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:58, content_type:'Ecom Products', slug:'ecom-products', icon:"ft-shopping-cart", path: '/content/ecom-products', title: 'Ecom Product',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:60, content_type:'Polls', slug:'polls', icon:"ft-bar-chart", path: '/content/polls', title: 'Polls',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            // {id:61, content_type:'Special Story', slug:'ampstory', icon:"ft-bar-chart", path: '/content/ampstory', title: 'Special Story',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []}
            
        ],
        IT:[
            // {id:0, content_type:"All", slug:"all", icon: 'ft-align-justify', path: '/content/all', title: 'All', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },            
            {id:1, content_type:'News', slug:'news', icon:"ft-file", path:'/content/news',title: 'News Article', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:51, content_type:'Video', slug:'videos', icon:"ft-film", path: '/content/videos', title: 'Videos', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:5, content_type:'Photogallery', slug:'photogallery', icon:"ft-image", path: '/content/photogallery', title: 'Photogallery', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            {id:54, content_type:'Click To Reveal', slug:'click-to-reveal', icon:"ft-aperture", path: '/content/click-to-reveal', title: 'Click to Reveal', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:55, content_type:'Slide To Reveal', slug:'slide-to-reveal', icon:"ft-chevron-left", path: '/content/slide-to-reveal', title: 'Slide to Reveal',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:56, content_type:'Movie Reviews', slug:'movie-reviews', icon:"ft-award", path: '/content/movie-reviews', title: 'Movie Review',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:53, content_type:'Quiz', slug:'quiz', icon:"ft-square", path: '/content/quiz', title: 'Quiz',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []}, 
            {id:52, content_type:'Trivia Quiz', slug:'trivia-quiz', icon:"ft-circle", path: '/content/trivia-quiz', title: 'Trivia Quiz', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:3, content_type:'Interviews', slug:'interviews', icon:"ft-target", path: '/content/interviews', title: 'Interviews', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {id:60, content_type:'Polls', slug:'polls', icon:"ft-bar-chart", path: '/content/polls', title: 'Polls',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            // {id:61, content_type:'Special Story', slug:'ampstory', icon:"ft-bar-chart", path: '/content/ampstory', title: 'Special Story',  class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []}
        ]
    },
    IMGLIMITS:{
        IDV:{
            MAX_WIDTH: 725,
            MAX_WIDTH_FLAG: false
        },
        MXP:{
            MAX_WIDTH: 725,
            MAX_WIDTH_FLAG: false
        },
        IT:{
            MAX_WIDTH: 725,
            MAX_WIDTH_FLAG: true
        }
    },
    CSSCLASSES:{
        IDV:[
            'contentCom',
            'buttonLink',
            'articlefigure',
            'picCaption',
            'figCap',
            'youtubeThumbnail',
            'articleImg',
            'quiz',
            'youtube-wrap'
        ],
        MXP:[
            'contentCom',
            'buttonLink',
            'articlefigure',
            'picCaption',
            'figCap',
            'youtubeThumbnail',
            'articleImg',
            'quiz',
            'youtube-wrap'
        ],
        IT:[
            'contentCom',
            'buttonLink',
            'articlefigure',
            'picCaption',
            'figCap',
            'youtubeThumbnail',
            'articleImg',
            'quiz',
            'youtube-wrap'
        ]
    },
    BOXTYPEFLAGS:{
        IDV:[]       
        ,
        MXP:[
            {'boxtype':'ampstory','flag_name':'is_ampstory'},
            {'boxtype':'shoparticle','flag_name':'is_shop'}
            
        ],
        IT:[]
    },
    AMP_PLACEHOLDER_IMG: 'https://img.mensxp.com/media/content/2020/Mar/amp-placeholder_5e81802e27e69.jpeg',
    HEADLINE : 'Visible Headline on the Article page (Optimize for a clickable copy. Include primary keyword)',
    PAGE_TITLE: 'This is Title That Shows Up on Google Search Listing. It should begin with SEO keywords & must contain some CTA (upto 60 Characters)',
    META_DESCRIPTION: 'This shows up as a description on your Google Search Listing. It should ideally have questions that you answered in the article. Treat this more like a summary. (upto 300 Characters).',
    SOCIAL_TITLE: 'This is Facebook headline. Should be exactly as Headline',
    SOCIAL_DESCRIPTION: 'This is the Facebook description. Should be catchy and contain questions that the article is going to address and answer.',
    TITLE: 'Use keywords and answer what is the image about (eg. Labrador Retriever - popular dog breed)',
    ALT_TEXT: 'Use keywords and write to explain what’s happening in the image to give more context(eg. a labrador dog playing in the rain)',
    PUBLISH_FLAG : 35,
    URL_HEADLINE:'URL slug in English (Should be different from headline, keyword rich, avoid numbers and year)',

    OTHER_LOCALE_CONTYPES:[
        {'slug':'news'},
        {'slug':'photogallery'}
    ],

    ADS_DENSITY:{
        IDV:{
            'field_id':4
        },
        MXP:{
            'field_id':7
        },
        IT:{
            'field_id':22
        }
    },
    SPEACIAL_STORY_TYPES:{
        'ampstory':'Amp Story',
        'amazonstory':'Amazon Story',
        'nativestory':'Native Story',
        'faq':'FAQs',
        'immersive_article':'Immerssive Article',
        'howtostory':'How To Story',
        'brand_article1':'Brand Article1',
        'brand_article2':'Brand Article2',
        'brand_article3':'Brand Article3',
        'brand_article4':'Brand Article4',
    },
    SPECIAL_STORY_DEFAULT_AMP_TEMP:{
        IDV:'AMP-Master-Template',
        MXP:'AMP-Master-Template',
    },
    SPECIAL_STORY_DEFAULT_AMP_COLOR:{
        IDV:'#ff5076', 
        MXP:'#ff6000',
    },
    STARGATE_API:{
        'url':'https://prodbot_api.timesinternet.in/api/v0.1/group/access/grant',
        'secret':'1f83cded54a1f9d1a2a8a54863ae7250c613ab8e'
    },
    RATINGS_TEMPLATE:{
        1:{
            IDV:{
                'template_id':32
            },
            MXP:{
                'template_id':47
            },
            IT:{
                'template_id':41
            }
        },
        2:{
            IDV:{
                'template_id':33
            },
            MXP:{
                'template_id':48
            },
            IT:{
                'template_id':42
            }
        },
        3:{
            MXP:{
                'template_id':58
            },
            IDV:{
                'template_id':45
            }
        }
    },
    PROPERTY_ID:{
        IDV:{
            'id':3
        },
        MXP:{
            'id':2
        },
        IT:{
            'id':1
        }
    },
    FORMDATA_TYPES:{
        IDV:{
            'static_box_id':14
        },
        MXP:{
            'static_box_id':15
        },
        IT:{
            'static_box_id':18
        }
    },
    LOCALE_IDS:{
        ENGLISH : 1,
        HINDI: 2,
        MARATHI : 3,
        BENGALI : 4
    },
    VIDEO_COMMERCE_TEMPLATE:{
        IDV:{
            'template_id':55
        },
        MXP:{
            'template_id':71
        },
        IT:{
            'template_id':66
        }
    },
    CREATIVE_ADS_TYPE:[
        {id:0, ads_type:'lightbox', slug:'lightbox', icon:"ft-copy", path:'/adscreative/lightbox',title: 'Lightbox', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
        {id:1, ads_type:'liew360', slug:'view360', icon:"ft-refresh-cw", path:'/adscreative/view360',title: '360 View', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
        {id:2, ads_type:'spinwheel', slug:'spinwheel', icon:"ft-crosshair", path:'/adscreative/spinwheel',title: 'Spin To Wheel', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
        {id:3, ads_type:'movie', slug:'movie', icon:"ft-video", path:'/adscreative/movie',title: 'Movie', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
        {id:4, ads_type:'comparison-card', slug:'comparison-card', icon:"fa fa-columns", path:'/adscreative/comparison-card',title: 'Comparison Card', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
        {id:5, ads_type:'slidetoreveal', slug:'slidetoreveal', icon:"fa fa-sliders", path:'/adscreative/slidetoreveal',title: 'Slide To Reveal', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
        {id:6, ads_type:'scratch-card', slug:'scratch-card', icon:"ft-square", path:'/adscreative/scratch-card',title: 'Scratch Card', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    ],
    BASIC_GOOGLE_DOC_FORMAT: 'https://docs.google.com/document/d/1OtEzXp9_44pWf0Dgl8d1QGUx2qXmUWUrfo9sBPhL1ZU/edit?usp=sharing',
    FB_BUSINESS_ID: {
        IDV: 3,
        MXP: 4
    },
    FB_TIME_PERIODS: [
        {label:'Today', value: 0},
        {label:'Yesterday', value: 1},
        {label:'Last 2 days', value: 2},
        {label: 'Last 3 days', value: 3},
        {label: 'Last 7 days', value: 7},
        {label: 'Last 14 days', value: 14},
        {label: 'Last 28 days', value: 28}
    ],
    FB_SORT_KEYS: [
        {label:'↑ CPM', value: 'final_cpm'},
        {label:'↓ CTR', value: 'final_ctr'},
        {label:'↑ CPC', value: 'final_cpc'},
        // {label:'Fluctuating CPM', value: 'std_cpm'},
        // {label:'Fluctuating CTR', value: 'std_ctr'},
        // {label:'Fluctuating CPC', value: 'std_cpc'},
        // {label:'CPM', value: 'final_cpm'},
        // {label:'CTR', value: 'final_ctr'},
        // {label:'CPC', value: 'final_cpc'}
    ],
    COUNTRY_IDS: [
        {id:1,name:"India"},
        {id:2,name:"USA"},
        // {id:3,name:"Rest"},
        // {id:4,name:"USA & Rest"},
    ],
    CONTENT_TYPES : [
        {label:'Article', value:'article', conTypeSlug: 'news'},
        {label:'Ampstory', value:'ampstory', conTypeSlug: 'news'},
        {label:'Photogallery', value:'photogallery', conTypeSlug: 'photogallery'}
    ],
    THUMB_IMAGES:{
        IDV:['verticle_pic_id','card_thumbnail_id','thumbnail_id'],
        MXP:['verticle_pic_id','card_thumbnail_id','thumbnail_id']
    },
    CONTENT_PROMPT_STATICBOX:{
        IDV:42,
        MXP:82,
    },
    ROADBLOCK_ADS_LIST:{
        IDV:44,
        MXP:84,
    },    
    GOOGLE_TRENDS_CATEGORIES:{
        IDV:43,
        MXP:83,
    }
};
  